

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 20px;

    @media (min-width: 1200px) {
        padding: 80px;
    }
}

.main-block {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: v-bind('finalTopBlockColorMobile');
    background-image: v-bind('topBlockBackgroundImageUrlCssMobile');
    padding: 30px 20px 20px 20px;
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
    z-index: 2;
    @media (min-width: 1200px) {
        border-radius: var(--border-radius-large);
        background-color: v-bind('finaltopBlockBackgroundDesktop');
        background-image: v-bind('topBlockBackgroundImageUrlCssDesktop');
        padding: 30px 20px 160px 20px;
    }
}

.info-block-inner {
    max-width : 450px;
    margin    : 0 auto;

    @media (min-width : 1200px) {
        max-width : 700px;
    }
}


.info-block-mobile {
    padding: 40px 10px;
    background-color: v-bind('finalBottomBlockColorMobile');
    background-image: v-bind('bottomBlockBackgroundImageUrlCssMobile');

    position: relative;


}

.info-block-desktop {
    position: relative;
   // background: var(--global-background);
    padding: 32px 60px 60px 60px;
    border-radius: 100px 100px var(--border-radius-large) var(--border-radius-large);
    box-shadow: 0 -30px 50px -7px rgba(0, 0, 0, 0.07);
    margin-top: -120px;
    z-index: 1;
    background-color: v-bind('finalBottomBlockColorDesktop');
    background-image: v-bind('bottomBlockBackgroundImageUrlCssDesktop');
}

.top-content {
    @media screen and (min-width: 1200px) {
        position: relative;
        z-index: 1;
    }

}

.bottom-content {
    @media screen and (min-width: 1200px) {
        position: relative;
        z-index: 2;
    }
}
